import {
  rdbV1NilRankingsList,
  rdbV1PersonSportRankingsList,
  v1CategoriesHeadlinesDetail2,
} from '@on3/api';
import withAuthentication from '@on3/ui-lib/src/hocs/withAuthentication';
import withValidUrl from '@on3/ui-lib/src/hocs/withValidUrl';
import { proxyApi } from '@on3/ui-lib/utils/api';
import type { AxiosError } from 'axios';
import {
  SoftballAmericaHome,
  type SoftballAmericaHomeProps,
} from 'components/Home/SoftballAmerica/SoftballAmericaHome';
import { PageWrapper } from 'components/Layouts/PageWrapper';
import type { GetServerSideProps } from 'next';

const title = `Softball America | The Top Source for Softball Stories, Rankings, and News`;
const description = 'The Top Source for Softball Stories, Rankings, and News';
const SoftballAmericaHomePage = (props: SoftballAmericaHomeProps) => {
  return (
    <PageWrapper {...props} description={description} title={title}>
      <SoftballAmericaHome {...props} />;
    </PageWrapper>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const softballAmericaSiteKey = 401;
  const siteData = await withValidUrl.isValid(ctx, softballAmericaSiteKey);
  const userData = await withAuthentication.isAuth(ctx);
  const { defaultYears } = siteData || {};
  const softballSportKey = 12;

  const nilParams = {
    limit: 5,
    sportKey: softballSportKey,
  };
  const rankingsParams = {
    year: defaultYears?.nonFootballRecruitingYear,
    sportKey: softballSportKey,
  };

  const mainCategoryParams = {
    category: 552031,
  };
  const collegeCategoryParams = {
    category: 552033,
  };
  const recruitingCategoryParams = {
    category: 552037,
  };
  const topStoriesCategoryParams = {
    category: 552043,
  };
  const proCategoryParams = {
    category: 552036,
  };

  try {
    const [
      latestArticles,
      collegeArticles,
      topStories,
      recruitingNews,
      proNews,
      nil100,
      topSoftballAthletes,
    ] = await Promise.allSettled([
      v1CategoriesHeadlinesDetail2(proxyApi, mainCategoryParams),
      v1CategoriesHeadlinesDetail2(proxyApi, collegeCategoryParams),
      v1CategoriesHeadlinesDetail2(proxyApi, topStoriesCategoryParams),
      v1CategoriesHeadlinesDetail2(proxyApi, recruitingCategoryParams),
      v1CategoriesHeadlinesDetail2(proxyApi, proCategoryParams),
      rdbV1NilRankingsList(proxyApi, nilParams),
      rdbV1PersonSportRankingsList(proxyApi, rankingsParams),
    ]);

    return {
      props: {
        pageType: 'home',
        siteData: siteData || [],
        userData: userData || {},
        latestArticles:
          latestArticles.status === 'fulfilled' ? latestArticles.value : null,
        recruitingNews:
          recruitingNews.status === 'fulfilled' ? recruitingNews.value : null,
        collegeArticles:
          collegeArticles.status === 'fulfilled' ? collegeArticles.value : null,
        topStories: topStories.status === 'fulfilled' ? topStories.value : null,
        proNews: proNews.status === 'fulfilled' ? proNews.value : null,
        nil100: nil100.status === 'fulfilled' ? nil100.value : null,
        topSoftballAthletes:
          topSoftballAthletes.status === 'fulfilled'
            ? topSoftballAthletes.value
            : null,
      },
    };
  } catch (error) {
    const err: AxiosError<unknown> = error as AxiosError<unknown>;

    console.log(err);

    return { props: {} };
  }
};

export default withValidUrl(SoftballAmericaHomePage);
