import { useAuth } from '@on3/ui-lib/src/contexts/AuthProvider';
import { useSite } from '@on3/ui-lib/src/contexts/SiteProvider';
import { PageType } from '@on3/ui-lib/types/common-props';
import { useEffect } from 'react';
import { initializeAds } from 'utils/ads/ads';

interface UseAdsProps {
  /** Pagetypes are based off of the units in admanger. */
  pageType: PageType;
  /** Inc units are units that can be repeated with an incrementer added on */
  incUnits?: number;
  tags?: string;
}

/** Custom hook for calling ads in the app */
export const useAds = ({ pageType, incUnits, tags }: UseAdsProps) => {
  const { currentSite } = useSite();

  const { user } = useAuth();
  const passedTeam = currentSite?.isNational ? 'on3' : currentSite?.slug;

  useEffect(() => {
    if (!user?.st?.includes('premium')) {
      initializeAds({
        pageType: pageType || 'home',
        team: passedTeam,
        siteType: currentSite?.type,
        userStatus: user?.a ? user?.st : 'guest',
        siteKey: currentSite?.key,
        incUnits,
        template: currentSite?.template,
        tags,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
